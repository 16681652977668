
import React, { useState, useEffect } from 'react';
import { Button, Popover, Position, Card, Elevation, FormGroup, MenuItem, Checkbox } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { globalStore } from './globalStore';
import logger from '../logger';
import '../styles/downloadPopover.css';
import { setDownScalingEnabled } from 'polotno/utils/flags';
setDownScalingEnabled(false);

const typesItems = [
    { label: 'PNG', value: 'png', mimeType: 'image/png' },
    { label: 'JPEG', value: 'jpeg', mimeType: 'image/jpeg' }
];

const DownloadPopover = observer(({ store ,triggerButton ,popoverPosition = Position.BOTTOM, buttonName = 'Download', externalFunc }) => {
    const [selectedPages, setSelectedPages] = useState([]);
    const [downloadPopoverOpen, setDownloadPopoverOpen] = useState(false);
    const [pagesPopoverOpen, setPagesPopoverOpen] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState(typesItems[0]);
    const [fileTypePopoverOpen, setFileTypePopoverOpen] = useState(false);

    // Initialize pages when opening the download popover
    const openPopover = () => {
        if (!downloadPopoverOpen) {  // Only set pages when opening
            if (store.pages?.length === 1) {
                setSelectedPages([0]);
            } else if (store.pages?.length > 1) {
                setSelectedPages(store.pages.map((_, index) => index));
            }
        }
        setDownloadPopoverOpen(!downloadPopoverOpen);
    };

    const handlePageToggle = (pageId) => {
        setSelectedPages(prev => {
            if (pageId === 'all') {
                // If "All" is selected, toggle all pages
                return prev.length === store.pages.length ? [] : store.pages.map((_, index) => index);
            } else {
                // Toggle individual page
                return prev.includes(pageId)
                    ? prev.filter(id => id !== pageId)
                    : [...prev, pageId];
            }
        });
    };

    const getSelectedPagesText = () => {
        if (!store.pages?.length) return '';
        if (store.pages.length === 1) return `Page 1${store.pages[0].custom?.pageName ? ` - ${store.pages[0].custom.pageName}` : ''}`;
    
        // For multiple pages
        if (selectedPages.length === store.pages.length) {
            return `All pages (${store.pages.length})`;
        }
    
        // When specific pages are selected
        const selectedPageLabels = selectedPages
            .sort((a, b) => a - b)
            .map(index => `Page ${index + 1}${store.pages[index].custom?.pageName ? ` - ${store.pages[index].custom.pageName}` : ''}`)
            .join(', ');
        return `${selectedPages.length} pages (${selectedPageLabels})`;
    };

    const handleDownload = async () => {
        const currentDateTime = format(new Date(), 'dd-MM-yyyy HH:mm');
        const templateName = globalStore.template.name;
        let downloadSuccessful = true;
    
         // Generate a file name based on page name and dynamic parameters.
        const generateFileName = (pageName) => {
            if (pageName) {
                return pageName
                    .replace(/{{template name}}/gi, templateName)
                    .replace(/{{time}}/gi, currentDateTime) +
                    `.${selectedFileType.value}`;
            } else {
                return `${templateName}_${currentDateTime}.${selectedFileType.value}`;
            }
        };
    
    
         //save single page as an image
        const savePageAsImage = async (page) => {
            const pageName = page?.custom?.pageName;
            const fileName = generateFileName(pageName);
            await store.saveAsImage({
                pageId: page.id,
                fileName,
                mimeType: selectedFileType.mimeType,
                ...(selectedFileType.value === 'png' && { ignoreBackground: true }),
            });
        };
    
        
         // Upload download history to the server.
        const uploadHistory = async () => {
            try {
                const response = await fetch(`${globalStore.serverUrl}/cloudtemplates/history`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                    },
                    body: JSON.stringify({ templateName }),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to update statistics');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                logger.logActionError("Error occurred - file was not downloaded!", {
                    templateName: templateName,
                    error: error?.message,
                    action: "Download",
                    entity: "file",
                });
            }
        };
    
        try {
            // Handle single or multiple pages
            if (store.pages?.length === 1) {
                await savePageAsImage(store.pages[0]);
            } else {
                for (const pageIndex of selectedPages) {
                    const page = store.pages[pageIndex];
                    await savePageAsImage(page);
                }
            }
        } catch (error) {
            downloadSuccessful = false;
            console.error("Download failed:", error);
        }
    
        if (downloadSuccessful) {
            await uploadHistory();
        }
    
        logger.logAction("file was downloaded", {
            templateName: templateName,
            action: "Download",
            entity: "file",
        });
        setDownloadPopoverOpen(false);
    };
    
    const handleFileTypeSelect = (fileType) => {
        setSelectedFileType(fileType);
        setFileTypePopoverOpen(false); 
    };

    const renderPageSelector = () => (
        <Card elevation={Elevation.TWO} className="page-selector-menu">
            <div className="page-selector-item">
                <Checkbox
                    checked={selectedPages.length === store.pages.length}
                    onChange={() => handlePageToggle('all')}
                    label="All"
                />
            </div>
            <div className="page-selector-list">
                {store.pages.map((page, index) => (
                    <div key={index} className="page-selector-item">
                        <Checkbox
                            checked={selectedPages.includes(index)}
                            onChange={() => handlePageToggle(index)}
                            label={`Page ${index + 1}${page?.custom?.pageName ? ` - ${page.custom.pageName}` : ''}`}
                        />
                    </div>
                ))}
            </div>
        </Card>
    );
    
    const handleAction = () => {
        if (externalFunc) {
            externalFunc(selectedPages, selectedFileType.value);
        } else {
            handleDownload();
        }
    };

    const renderDownloadMenu = () => (
        <Card elevation={Elevation.TWO} className="download-menu-pages">
            <FormGroup label="File Type" labelFor="file-type">
                <Select
                    filterable={false}
                    popoverProps={{
                        minimal: true,
                        matchTargetWidth: true,
                        onInteraction: (state) => setFileTypePopoverOpen(state),
                        isOpen: fileTypePopoverOpen,
                        captureDismiss: true
                    }}
                    items={typesItems}
                    itemRenderer={(item, { handleClick, modifiers }) => {
                        if (!modifiers.matchesPredicate) {
                            return null;
                        }
                        return (
                            <MenuItem
                                active={modifiers.active}
                                key={item.value}
                                onClick={handleClick}
                                text={item.label}
                            />
                        );
                    }}
                    onItemSelect={handleFileTypeSelect}
                >
                    <Button
                        className="bp5-button bp5-fill bp5-align-left"
                        text={selectedFileType.label}
                        rightIcon="caret-down"
                    />
                </Select>
            </FormGroup>
            <FormGroup label="Select Pages" labelFor="select-pages">
                {!store.pages?.length ? (
                    <Button
                        className="bp5-button bp5-fill bp5-align-left"
                        text=""
                        disabled={true}
                    />
                ) : store.pages.length === 1 ? (
                    <Button
                        className="bp5-button bp5-fill bp5-align-left"
                        text={`Page 1${store.pages[0]?.custom?.pageName ? ` - ${store.pages[0].custom.pageName}` : ''}`}
                    />
                ) : (
                    <Popover
                        content={renderPageSelector()}
                        position={Position.BOTTOM}
                        isOpen={pagesPopoverOpen}
                        onInteraction={(state) => setPagesPopoverOpen(state)}
                        minimal
                        className="popover-custom-width"  // Apply the custom class
                    >
                        <Button
                            className="bp5-button bp5-fill bp5-align-left"
                            text={getSelectedPagesText()}
                            rightIcon="caret-down"
                        />
                    </Popover>
                )}
            </FormGroup>
            <Button
                intent="primary"
                className="download-button-cloud-editor"
                onClick={handleAction}
                disabled={store.pages?.length === 0 || (store.pages?.length > 1 && selectedPages.length === 0)}
            >
                {buttonName}
            </Button>
        </Card>
    );

    return (
        <Popover
            content={renderDownloadMenu()}
            isOpen={downloadPopoverOpen}
            onInteraction={(state) => setDownloadPopoverOpen(state)}
            position={popoverPosition} 
        >
            {triggerButton ? (
                React.cloneElement(triggerButton, { onClick: openPopover })
            ) : (
                <Button
                    intent="primary"
                    minimal
                    className="bp3-intent-primary"
                    icon="import"
                    onClick={openPopover}
                >
                    Download
                </Button>
            )}
        </Popover>
    );
});

export default DownloadPopover;