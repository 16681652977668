import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { getImageSize } from 'polotno/utils/image';
import { globalStore } from './globalStore';
import MdPhoto from '@meronex/icons/md/MdPhoto';
import { SectionTab } from 'polotno/side-panel';
import { Thumbnail } from './thumbnail';
import { InputGroup } from '@blueprintjs/core';
import { useScroll } from 'react-spring';
import logger from '../logger';
import { unstable_registerNextDomDrop } from 'polotno/config';


export const Panel = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  const [isUploading, setUploading] = React.useState(false);
  const [currentFolder, setCurrentFolder] = React.useState('');
  const [currentFolderName, setCurrentFolderName] = React.useState('');
  const [parentFolder, setParentFolder] = React.useState('');
  const [isCreatingFolder, setCreatingFolder] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    load(currentFolder);
  }, [globalStore.assetsRefreshCounter, currentFolder]);

  const load = async (f, q) => {
    const queryParams = new URLSearchParams();
    if (q) {
        queryParams.append('q', q.currentTarget.value);
    }
    queryParams.append('type', 'non-font');

    const response = await fetch(`${globalStore.serverUrl}/cloudassets/${f || ''}?${queryParams.toString()}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
        }
    });

        if(response.ok){
            const data = await response.json();
            setCurrentFolder(data.current_folder);
            setParentFolder(data.parent_folder);
            setCurrentFolderName(data.current_folder_name);
            setImages(data.assets);
            globalStore.setCurrentAssetFolder(data.current_folder, data.current_folder_name);
        }
        setIsLoading(false);
    };

    const uploadAsset = async(file) => {
        try {
            const url = `${globalStore.serverUrl}/cloudassets/get-presigned-url/image/${currentFolder || ''}?ext=${file.type.split('/')[1]}&contentType=${file.type}&filename=${file.name}`;
        
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
            });
            const { signedUrl, key, asset_id} = await response.json();
            
            const uploadResponse = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload file to cloud storage');
            }

            await fetch(`${globalStore.serverUrl}/cloudassets/asset/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            },
            body: JSON.stringify({ 
                asset_id, 
                key, 
                filename: file.name, 
                contentType: file.type,
                currentFolder: currentFolder || '',
                type: 'image'

            }),
        });
        logger.logAction("Image Asset was Added",{asset_id:asset_id,filename:file.name,currentFolder:currentFolder||'',action:"Add",entity:"image asset" })
        } catch (error) {
            console.error('Error uploading file:', error);
            logger.logActionError("Error accured - Image Asset was not Added",{error: error?.message,asset_id:asset_id,filename:file.name,currentFolder:currentFolder||'',action:"Add",entity:"image asset" })
        }
    };
    

    const handleFileInput = async (e) => {
        const { target } = e;
        setUploading(true);
        for (const file of target.files) {
            await uploadAsset(file);
        }
        await load(currentFolder);
        setUploading(false);
        target.value = null;
    };

    const handleCreateFolder = async (e) => {
        const folderName = prompt("Create Folder", 'New Folder');
        if (folderName && folderName !== '') {
            setCreatingFolder(true);
            try {
                const params = {
                    name: folderName,
                    type: 'folder',
                    ...(currentFolder ? { folder: currentFolder } : {})
                };
    
                const response = await fetch(`${globalStore.serverUrl}/cloudassets/folder/${currentFolder || ''}`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                    },
                    body: JSON.stringify(params),
                });
                await load(currentFolder);
                logger.logAction("Folder was created", { folderName :folderName,action:"Add",entity:"folder" });
            } catch (error) {
                console.error("Exception occurred while creating folder:", error);
                logger.logActionError("Exception occurred - Folder was not created", { error: error?.message ,folderName :folderName ,action:"Add",entity:"folder"});
            } finally {
                setCreatingFolder(false);
            }
        }
    };
    

    const handleRename = async (asset) => {
        const newName = prompt("Rename Asset:", asset.name);
        if(newName && newName !== '' && newName !== asset.name){
            const res = await fetch(`${globalStore.serverUrl}/cloudassets/${asset._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
                body : JSON.stringify({
                    name: newName
                })
            });

            if(res.ok){
                await load(currentFolder);
            }
        }
    }

    const handleSelect = async(asset) => {
        if(asset.type==='image') await selectImage(asset);
        else if(asset.type==='folder') await selectFolder(asset);
    }

    const selectFolder = async(asset) =>{
        await load(`${currentFolder}/${asset._id}`);
    }

    const selectImage = async (asset) => {
        const { url } = asset;
        const element = store.selectedElements[0];
      
        if (element && element.type === "image" && !element.locked) {
          element.set({ src: url });
          return;
        }
      
        await addImageToStore({ store, url, name: asset.name });
    };

    const handleBack = async() => {
        await load(parentFolder);
    }

const checkAssetUsage = async (assetId, serverUrl, apiKey) => {
    const response = await fetch(`${serverUrl}/cloudassets/check-asset-usage/${assetId}`, {
        method: 'GET',
        headers: {
            ...(apiKey ? { "x-api-key": apiKey } : {}),
        }
    });

    if (!response.ok) {
        throw new Error("Failed to check asset usage");
    }

    const { count } = await response.json();
    return count;
};

const getDeleteConfirmation = (templatesNumber) => {
    const baseMessage = "Asset will be permanently deleted. Are you sure?";
    const templateMessage = `This asset is currently used in ${templatesNumber} template(s). Deleting it will remove it from these templates, and it will no longer appear in them. Are you sure you want to continue?`;
    
    return window.confirm(templatesNumber > 0 ? templateMessage : baseMessage);
};

const removeAssetFromTemplates = async (assetId, serverUrl, apiKey) => {
    const response = await fetch(`${serverUrl}/cloudassets/asset/remove-from-templates`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(apiKey ? { "x-api-key": apiKey } : {}),
        },
        body: JSON.stringify({ assetId })
    });

    if (!response.ok) {
        throw new Error("Failed to remove asset from templates");
    }

    const responseData = await response.json();
    
    if (!responseData.templates?.length) {
        throw new Error("No templates found in response");
    }

    return responseData.templates;
};

const updateTemplateThumbnail = async (template, store, serverUrl, apiKey) => {
    try {
        if (!template || !store || !serverUrl) {
            throw new Error('Missing required parameters for updating template thumbnail');
        }
        try {
            store.loadJSON(template.content);
        } catch (error) {
            throw new Error(`Failed to load template content: ${error.message}`);
        }

        let dataURL;
        try {
            dataURL = await store.toDataURL({
                pixelRatio: 0.5,
                mimeType: 'image/jpeg'
            });
        } catch (error) {
            throw new Error(`Failed to generate thumbnail: ${error.message}`);
        }

        const data = {
            id: template._id,
            name: template.name,
            content: template.content,
            imageData: dataURL,
        };

        const response = await fetch(`${serverUrl}/cloudtemplates`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                ...(apiKey ? { "x-api-key": apiKey } : {}),
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => 'Unknown error');
            throw new Error(`Failed to update template ${template._id}: ${errorText}`);
        }

        return template._id;
    } catch (error) {
        console.error(`Error in updateTemplateThumbnail for template ${template?._id}:`, error);
        throw error;
    }
};

const deleteAsset = async (assetId, serverUrl, apiKey) => {
    try {
        const response = await fetch(`${serverUrl}/cloudassets/${assetId}`, {
            method: 'DELETE',
            headers: {
                ...(apiKey ? { "x-api-key": apiKey } : {}),
            }
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => 'Unknown error');
            throw new Error(`Failed to delete asset ${assetId}: ${errorText}`);
        }
        return true; 
    } catch (error) {
        console.error(`Error in deleteAsset for asset ${assetId}:`, error);
        throw error; 
    }
};

const handleDelete = async (asset) => {
    try {
        const templatesNumber = await checkAssetUsage(
            asset._id, 
            globalStore.serverUrl, 
            globalStore.apiKey
        );

        if (!getDeleteConfirmation(templatesNumber)) {
            return;
        }
        if (templatesNumber > 0) {
            const templates = await removeAssetFromTemplates(
                asset._id, 
                globalStore.serverUrl, 
                globalStore.apiKey
            );
            for (const template of templates) {
                try {
                    const updatedTemplateId = await updateTemplateThumbnail(
                        template, 
                        store, 
                        globalStore.serverUrl, 
                        globalStore.apiKey
                    );
                } catch (error) {
                    console.error(`Error updating template: ${template._id}`, error);
                    continue;
                }
            }

            globalStore.contentChanged = false;
            await globalStore.loadTemplates();
            logger.logAction("Templates were Saved", {
                action: "save",
                entity: "templates",
                count: templates.length
            });
        }
        await deleteAsset(asset._id, globalStore.serverUrl, globalStore.apiKey);
        await load(currentFolder);
        await globalStore.loadTemplates();

    } catch (error) {
        console.error("Error in handleDelete:", error);
    }
};

const addImageToStore = async ({ store, url, pos = null, name = "" }) => {
    if (!store || !url) return;
  
    let { width, height } = await getImageSize(url);
    const isSVG = url.includes("svg+xml") || url.includes(".svg");
    const type = isSVG ? "svg" : "image";
  
    // Scale image size to fit within workspace
    const scale = Math.min(store.width / width, store.height / height, 1);
    width *= scale;
    height *= scale;
  
    // Determine position (center if no position is provided)
    const x = pos ? pos.x - width / 2 : store.width / 2 - width / 2;
    const y = pos ? pos.y - height / 2 : store.height / 2 - height / 2;
  
    store.activePage?.addElement({
      type,
      src: url,
      x,
      y,
      width,
      height,
      name,
    });
  };
  

  React.useEffect(() => {
    load();
  }, []);

    return (
        <div className='flex flex-col h-full'>
            <div className='flex flex-col mb-1'>
                <div className='flex mb-1'>
                    <label htmlFor="input-file" className='w-1/2 mb-0 font-normal flex-initial'>
                        <Button icon="media" style={{ width: '100%' }} onClick={() => {document.querySelector('#input-file')?.click();}} loading={isUploading}>Upload</Button>
                        <input type="file" id="input-file" style={{ display: 'none' }} onChange={handleFileInput} multiple/>
                    </label>
                    <Button icon="folder-new" className='w-1/2' onClick={handleCreateFolder} loading={isCreatingFolder}>New Folder</Button>
                </div>
                <InputGroup leftIcon="search" placeholder="Search..." onChange={(e) => {load(currentFolder, e);}}/>
            </div>
            <div className='flex items-center mb-1'>
                {currentFolder && <Button icon="arrow-left" className='mr-1'  onClick={handleBack} loading={isCreatingFolder}></Button>}
                <span className='text-xl font-bold mb-1'>{currentFolderName}</span>
            </div>
            <div className='grid grid-cols-2 gap-[4px] overflow-y-auto overflow-x-hidden'>
            {images.map((image) => (
                <div
                    key={image._id}
                    draggable
                    onDragStart={(event) => {
                    console.log("Drag started for", image.url);
                    event.dataTransfer.setData("text/plain", image.url);

                    unstable_registerNextDomDrop(async (pos, element) => {
                        console.log("Dropped at:", pos);

                        if (element && element.type === "image") {
                        console.log("Replacing existing image");
                        element.set({ src: image.url });
                        return;
                        }

                        console.log("Creating new image element at:", pos);
                        await addImageToStore({ store, url: image.url, pos, name: image.name });
                    });
                    }}
                    onDragEnd={() => {
                    console.log("Drag ended");
                    unstable_registerNextDomDrop(null);
                    }}
                >
                    <Thumbnail
                    src={image.type === "image" ? image.url : "https://d2f9eheulyqk7v.cloudfront.net/internal/folder.svg"}
                    name={image.name}
                    onSelect={() => handleSelect(image)}
                    allowRename={globalStore.userRole === "admin"}
                    allowDelete={globalStore.userRole === "admin"}
                    onRename={() => handleRename(image)}
                    onDelete={() => handleDelete(image)}
                    />
                </div>
                ))}

                {/* {images.map((image, index) => 
                <Thumbnail key={image._id} src={image.type==='image' ? image.url : 'https://d2f9eheulyqk7v.cloudfront.net/internal/folder.svg'} name={image.name}
                onSelect={()=>handleSelect(image)}
                allowRename={globalStore.userRole==='admin'}
                allowDelete={globalStore.userRole==='admin'}
                onRename={()=> handleRename(image)} 
                onDelete={()=> handleDelete(image)}
                />)} */}
            </div>
        </div>
    );
});

export const AssetsSection = {
    name: 'assets',
    Tab: (props) => (
      <SectionTab name="Assets" {...props} >
        <span className='bp5-icon bp5-icon-fullscreen'>
            <MdPhoto height="16px" style={{height: '16px', width: '16px'}}/>
        </span>
      </SectionTab>
    ),
    Panel: Panel,
  };
