import { useEffect, useState, useRef } from "react";
import { createStore } from 'polotno/model/store';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { Button, Divider ,Position} from '@blueprintjs/core';
import { Cross } from "@blueprintjs/icons";
import { globalStore} from "./components/globalStore";
import { TemplatesPanel } from "./components/templates";
import { AssetsSection } from "./components/assets";
import { TemplateName } from "./components/templateName";
import { TemplateSaveButton } from "./components/templateSaveButton";
import fontsFunctions from "./fontsFunctions";
import { TemporaryAssetsSection } from "./components/temporeryFiles";
import {AIGeneratorSection} from './components/generativeSection'
import DownloadPopover from "./components/downloadPopover";
import { handleSelectTemplate } from './components/templates';
import { observer } from 'mobx-react-lite';
import { TextSection, ElementsSection, TemplatesSection, LayersSection, SizeSection } from 'polotno/side-panel';

import '@blueprintjs/core/lib/css/blueprint.css';
import CustomWorkspace from "./components/customWorkspace";

const Designer = observer(({options ,onSaveImage, onClose}) => {
    const { apiKey, template, imageApiEndpoint, apiEndpoint, temp_assets,allowClose,saveEditButtonText} = options;
    const [userRole, setUserRole] = useState('user');
    const [defaultSection, setDefaultSection] = useState('');
    const [sections, setSections] = useState([
        AssetsSection,
        TextSection,
        ElementsSection,
        AIGeneratorSection
    ]);

    const observer = new MutationObserver(() => {
        const p = document.querySelector('.polotno-workspace-inner p');
        const button = document.querySelector('.polotno-workspace-inner button');
        if (p && button) {
          p.textContent = 'No template selected';
          button.textContent = '';
          observer.disconnect();
        }
      });
      
      observer.observe(document.body, { childList: true, subtree: true });

      
    useEffect(() => {
        if (temp_assets && temp_assets.length > 0) {
            setSections(prevSections => {
                const sectionExists = prevSections.some(section => section.name === 'Temporary-assets');
                
                if (!sectionExists) {
                    return [
                        ...prevSections,
                        {
                            ...TemporaryAssetsSection,
                            Panel: (props) => (
                                <TemporaryAssetsSection.Panel
                                    {...props}
                                    tempAssets={temp_assets}
                                />
                            ),
                        }
                    ];
                }
                return prevSections;
            });
    
            setDefaultSection('Temporary-assets');
        } else {
            setDefaultSection('templates');
        }
    }, [temp_assets]);
    

    const store = useRef(createStore({
        key: 'YzDDTxVKl0nzwkoZcdVb',
        showCredit: false
    })).current;

    const loadTemplate = async() => {
        let req = `${globalStore.serverUrl}/cloudtemplates/${template}`;

        if (typeof template === 'object') {
            store.loadJSON(template.content);
            globalStore.template = template;
            globalStore.contentChanged = false;
            return; 
        }
        if(template.toLowerCase().indexOf(imageApiEndpoint)===0){
            req = template.replace(/\/image(?=\?)/, '/data');
        }
    
        const res = await fetch(req, {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            }
        });
        const json = await res.json();
        store.loadJSON(json.content);
        globalStore.template = json;
        globalStore.contentChanged = false;
    }
    const generateURL = async (templateId,farmId,templateName,pagesIndexes, fileType) => {
        let url =  `${imageApiEndpoint}/${farmId}/${templateId}/image?__output=${fileType}&__page=0`
        onSaveImage({url: url,templateName:templateName,pagesIndexes:pagesIndexes});
    }

    const saveImage = async (pagesIndexes, fileType) => {
        const { id, ...templateWithoutId } = globalStore.template;
        const dataURL = await store.toDataURL({ pixelRatio: 0.5 ,mimeType: 'image/jpeg'});
        const data = {
            ...templateWithoutId,
            content: store.toJSON(),
            imageData: dataURL,
            ...(id ? { parent: id } : {}),
            ...(globalStore.template.ancestor ? { ancestor: globalStore.template.ancestor } : (id ? { ancestor: id } : {}))
        };
    
        const res = await fetch(`${globalStore.serverUrl}/cloudtemplates`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            },
            body: JSON.stringify(data)
        });
        if (res.ok) {
            const savedTemplate = await res.json();
            await generateURL(savedTemplate._id,savedTemplate.farm,savedTemplate.name,pagesIndexes, fileType);
        } else {
            console.error("Error saving template:", res.statusText);
        }
    };

    const generateTemplateUrl  = async () => {

        if (!globalStore.template || !globalStore.template.id) {
            console.error("Template not found");
            return null;
        }
    
        const template = globalStore.template;
        const content = store.toJSON();
        const pages = content.pages;
    
        if (!pages || pages.length === 0) {
            console.error("No pages found in template");
            return null;
        }
    
        const generateUrl = (page) => {
            let url = `${imageApiEndpoint}/${globalStore.farmId}/${template.id}/image?`;
    
            page.children.forEach((layer) => {
                let layerName = layer.name ? layer.name : layer.id;
                layerName = layerName.replace(/\s+/g, "_");
                if (layer.type === "text" && layer.text) {
                    url += `${layerName}=${encodeURIComponent(layer.text)}&`;
                }
                if (layer.type === "figure" && layer.fill) {
                    url += `${layerName}=${encodeURIComponent(layer.fill)}&`;
                }
                if (layer.type === "image" && layer.src) {
                    url += `${layerName}=${layer.src}&`;
                }
            });
    
            if (url.endsWith("&")) {
                url = url.slice(0, -1);
            }
    
            return url;
        };
    
        const url = generateUrl(pages[0]);
        onSaveImage({url: url});
    };
    
    const handleClose = () => {
        if(globalStore.contentChanged && !window.confirm("All changes will be lost! Are you sure?")) return;
        onClose();
    }

    const CustomControls = ({ store}) => {
        return (
            <div style={{display: "flex"}}>
                <DownloadPopover store={store}/>
                <Divider style={{visibility: "hidden"}}/>
                {globalStore.userRole === 'admin' && (!template || typeof template !== 'object') &&<TemplateSaveButton store={store}/>}
            </div>
        );
    };

    const determineUserRole = (role) => {
        if (role === 'admin' || role === 'manager') {
            globalStore.userRole = 'admin';
        } else {
            globalStore.userRole = 'user';
        }

    };

    const fetchUserData = async () => {
        let url = `${globalStore.serverUrl}/user/me`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });
        const user = await res.json();
        const role = user?.farms[0]?.role;
        determineUserRole(role);
        store.setRole(globalStore.userRole);
        setUserRole(globalStore.userRole);
        globalStore.farmName = user?.farms[0]?.farm.name;
        globalStore.farmId =  user?.farms[0]?.farm._id;
        globalStore.userName = user?.full_name;
        globalStore.userId = user?._id;

    };

    useEffect(() => {
        const init = async () => {
            globalStore.serverUrl = apiEndpoint;
            globalStore.apiKey = apiKey;
            globalStore.contentChanged = false;
            globalStore.showZoom = false;
            globalStore.template = {
                name: 'New Template'
            };
            // store.addPage();
            TemplatesSection.Panel = TemplatesPanel;
    
            const unsubscribeKey = store.on('change', (updatedStore)=>{
                fontsFunctions.manageFonts(updatedStore.fonts);
                globalStore.contentChanged = true;
            });

            if (options.templateId) {
                await handleSelectTemplate(store, {}, `${globalStore.serverUrl}/cloudtemplates/${options.templateId}`);
            } else if (template) {
                await loadTemplate();
            }
    
            await fetchUserData();

            setSections((prevSections) => {
                // Remove any existing instances of AIGeneratorSection and ensure unique sections
                let newSections = prevSections.filter((section, index, self) => 
                    section !== AIGeneratorSection && self.indexOf(section) === index
                );
            
                // Add SizeSection for admin users if not already present
                if (globalStore.userRole === 'admin' && !newSections.includes(SizeSection)) {
                    newSections.push(SizeSection);
                }
                // Ensure TemplatesSection exists if no template is loaded
                if (options?.showAllTemplates || (!template && !newSections.includes(TemplatesSection))) {
                    newSections.unshift(TemplatesSection);
                }
            
                // Ensure AIGeneratorSection is added exactly once
                if (!newSections.includes(AIGeneratorSection)) {
                    const sizeIndex = newSections.indexOf(SizeSection);
                    if (sizeIndex !== -1) {
                        // If SizeSection exists, insert AIGeneratorSection after it
                        newSections.splice(sizeIndex + 1, 0, AIGeneratorSection);
                    } else {
                        // Otherwise, add it to the end
                        newSections.push(AIGeneratorSection);
                    }
                }
            
                return newSections;
            });
            return unsubscribeKey;
        };

        init().catch(console.error);
    
    }, [template]);

    useEffect(() => {
        fontsFunctions.setFontsGlobally().then((fonts) => {
            fonts.forEach(font => {
                store.addFont(font);
            });
            globalStore.fontsInProgress = false;
        });
    }, []);

    useEffect(() => {
        const messageElement = document.querySelector('div[style*="position: absolute"] p');
        if (messageElement) {
          messageElement.textContent = "No template selected";
        }
      }, []); // Empty dependency array ensures it runs once when the component mounts.

    return (
        <PolotnoContainer className="flex flex-col bg-white">
             {onSaveImage && (
                <div className="py-2 pl-1 pr-5 flex items-center justify-between border border-t-gray-300 bg-gray-100">
                    <a href="https://www.templatefarm.io" target="_blank" rel="noreferrer" className="outline-none">
                        <img className="h-8" src="https://d2f9eheulyqk7v.cloudfront.net/internal/te-logo.svg" alt="TELogo" />
                    </a>
                    <div className="flex">
                        {allowClose !== false && (
                            <Cross className="opacity-50 hover:opacity-100 cursor-pointer" onClick={handleClose} />
                        )}
                    </div>
                </div>
            )}
            <div className="flex border border-b-gray-200">
                <div className="flex items-center w-auto bp5-navbar">
                    <TemplateName store={globalStore} polotnoStore={store} template={template}/>
                </div>
                <Toolbar store={store} components={{ActionControls: CustomControls}}/>
            </div>
            <div className="flex flex-auto overflow-y-hidden">
                <SidePanelWrap>
                {defaultSection && (
                        <SidePanel store={store} sections={sections} defaultSection={defaultSection} />
                    )}
                </SidePanelWrap>
                <WorkspaceWrap>
                    <CustomWorkspace store={store}entityType="pages"/>
                    {globalStore.showZoom && <ZoomButtons store={store} />}
                </WorkspaceWrap>
                <div className="p-1 min-w-80">
                    <LayersSection.Panel store={store} />
                </div>
            </div>
            {onSaveImage && (
                <div className="py-2 px-3 flex justify-between border border-t-gray-300 bg-gray-100">
                    <div className="flex">
                    </div>
                    <div className="flex">
                        {options.generateTemplateURLOnSave ? (
                            <Button
                                intent="primary"
                                icon="circle-arrow-down"
                                onClick={generateTemplateUrl}
                            >
                                {saveEditButtonText || 'Save'}
                            </Button>
                        ) : (
                            <DownloadPopover
                                store={store}
                                popoverPosition={Position.BOTTOM_RIGHT}
                                buttonName="Continue"
                                externalFunc={saveImage}
                                triggerButton={
                                    <Button
                                        intent="primary"
                                        icon="circle-arrow-down"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {saveEditButtonText || 'Save'}
                                    </Button>
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </PolotnoContainer>
  );
});

export default Designer
